html,
body {
  position: relative;
  height: 100%;
}

.swiper-container {
  @apply h-auto lg:h-full;
  margin-left: auto;
  margin-right: auto;
}

.splide__slide {
    @apply flex flex-col justify-end h-auto  !important;
   
img{
    @apply mx-auto;
}
p{
    @apply  block text-center text-white text-s font-bold mt-9 ;
}
  /* Center slide text vertically */

}